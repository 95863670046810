import { Accessor, createEffect, Index, type JSX } from "solid-js";
import { A, useMatch } from "@solidjs/router";
import { Listup } from "~/components/icons/listup";
import ChartBar from "lucide-solid/icons/chart-bar";
import Settings from "lucide-solid/icons/settings";
import Waiting from "lucide-solid/icons/mail-question";
import User from "lucide-solid/icons/user";
import LogoutIcon from "lucide-solid/icons/log-out";
import LayoutTemplate from "lucide-solid/icons/layout-template";

const sections = [
  {
    icon: <ChartBar class="w-5 h-5" />,
    label: "Metrics",
    href: "/dash/metrics",
  },
  {
    icon: <LayoutTemplate class="w-5 h-5" />,
    label: "Template",
    href: "/dash/template",
  },
  {
    icon: <User class="w-5 h-5" />,
    label: "Subscribers",
    href: "/dash/subscribers",
  },
  {
    icon: <Waiting class="w-5 h-5" />,
    label: "Unverified",
    href: "/dash/unverified",
  },
  {
    icon: <Settings class="w-5 h-5" />,
    label: "Account",
    href: "/dash/account",
  },
] as const;

interface NavItemProps {
  section: Accessor<{
    href: string;
    icon: JSX.Element;
    label: string;
  }>;
}

function NavigationItem(props: NavItemProps) {
  const active = useMatch(() => props.section().href);

  return (
    <A
      href={props.section().href}
      class={`flex items-center gap-3 px-4 py-2 rounded-lg cursor-pointer mb-1 ${
        active()?.path
          ? "bg-indigo-50 dark:bg-sky-900/50 text-sky-600 dark:text-sky-400"
          : "text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50"
      }`}
    >
      {props.section().icon}
      <span class="font-medium">{props.section().label}</span>
    </A>
  );
}

function NavList() {
  return (
    <nav class="p-4">
      <Index each={sections}>
        {(section) => <NavigationItem section={section} />}
      </Index>
    </nav>
  );
}

export function DashNav() {
  return (
    <div class="w-64 inset-y-0 backdrop-blur-3xl border-r border-gray-200 dark:border-gray-700 relative">
      <div class="flex items-center gap-2 px-6 py-4 border-b border-gray-200 dark:border-gray-700">
        <A href="/" class="flex gap-4">
          <Listup class="w-8 h-8" />
          <span class="font-semibold text-xl dark:text-neutral-150">
            ListUp
          </span>
        </A>
      </div>
      <NavList />
      <A
        href="/"
        class={`absolute bottom-0 pb-5 w-full flex items-center gap-3 px-4 py-2 cursor-pointer mb-1 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700/50`}
      >
        <LogoutIcon class="w-5 h-5 rotate-180" />
        <span class="font-medium">Logout</span>
      </A>
    </div>
  );
}
